<template>
    <Dialog v-model:visible="showErrorDialog" :breakpoints="{'960px': '75vw', '640px': '90vw'}"
            :header="`Evento: ${currentModalEventoId}`" :modal="true" :style="{width: '50vw'}">
        <DataTable v-if="currentModalErrors" :value="currentModalErrors" responsiveLayout="scroll">
            <Column field="codigo" header="Código"></Column>
            <Column field="descricao" header="Descrição"></Column>
        </DataTable>
        <div v-if="currentModalError" class="p-fluid grid">
            <div class="field col-12 m-0">
                <Textarea v-model="currentModalError" rows="5"></Textarea>
            </div>
        </div>
    </Dialog>
</template>

<script>
export default {
    name: "DialogShowError",
    data() {
        return {
            showErrorDialog: false,
            currentModalEventoId: undefined,
            currentModalErrors: undefined,
            currentModalError: undefined,
        }
    },
    methods: {
        show(data) {
            this.showErrorDialog = true;
            this.currentModalEventoId = data.eventoId;

            try {
                this.currentModalErrors = JSON.parse(data.esocialErros);
            } catch (e) {
                this.currentModalError = data.esocialErros;
            }
            this.currentModalErrors = JSON.parse(data.esocialErros);
        }
    }
}
</script>
