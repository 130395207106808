<template>
    <Dialog v-model:visible="showXmlDialog" :breakpoints="{'960px': '75vw', '640px': '90vw'}"
            :header="`Evento: ${currentModalEventoId}`" :modal="true" :style="{width: '50vw'}">
        <div class="p-fluid grid">
            <div class="field col-12 m-0">
                <Textarea v-model="currentXml" :readonly="true" rows="30"></Textarea>
            </div>
        </div>
    </Dialog>
</template>

<script>
import vkbeautify from 'vkbeautify';
import { showError } from '@/utils/Toast';
import EventosService from './services/EventosService';

export default {
    name: 'DialogShowXml',
    props: {
        loading: {
            type: Boolean,
            required: true
        }
    },
    data() {
        return {
            showXmlDialog: false,
            currentModalEventoId: undefined,
            currentXml: undefined,
        };
    },
    mounted() {
        this.$service = new EventosService();
    },
    computed: {
        compLoading: {
            get() {
                return this.loading;
            },
            set(value) {
                this.$emit('update:loading', value);
            }
        }
    },
    methods: {
        async show(id) {
            this.compLoading = true;
            try {
                const { data } = await this.$service.getXml(id);

                this.currentModalEventoId = data.eventoId;
                this.currentXml = vkbeautify.xml(data.eventoXml);
                this.showXmlDialog = true;
            } catch (error) {
                showError(this.$toast, error);
            } finally {
                this.compLoading = false;
            }
        }
    }
};
</script>

<style scoped>

</style>
