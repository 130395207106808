<template>
    <Dialog v-model:visible="showCancelDialog" :breakpoints="{'960px': '75vw', '640px': '90vw'}"
            :modal="true" :style="{width: '50vw'}" header="Cancelar eventos">
        <DataTable v-if="selectedRecords" :value="selectedRecords" responsiveLayout="scroll">
            <Column header="Tipo">
                <template #body="item">
                    {{ EsocialPessoaTipoEnumLabel.get(item.data.empregadorTipo) }}
                </template>
            </Column>
            <Column field="empregadorInscricao" header="Inscrição"></Column>
            <Column header="CPF do trabalhador">
                <template #body="item">
                    {{ mCPF(item.data.trabalhadorCpf) }}
                </template>
            </Column>
            <Column field="eventoTipo" header="Evento"></Column>
            <Column field="esocialRecibo" header="Recibo"></Column>
        </DataTable>
        <template #footer>
            <Button class="p-button-text" icon="pi pi-times" label="Cancelar" @click="showCancelDialog = false"/>
            <Button class="p-button-outlined p-button-danger" icon="pi pi-trash" label="Gerar S-3000"
                    @click="cancelRecords"/>
        </template>
    </Dialog>
</template>

<script>
import { showError, showSuccess } from '@/utils/Toast';
import { EsocialPessoaTipoEnumLabel } from '@/pages/esocial/eventos/enums/EsocialPessoaTipoEnum';
import { mCPF } from '@/utils/Mask';
import EventosService from './services/EventosService';

export default {
    name: 'DialogShowCancel',
    props: {
        selectedRecords: {
            type: Array,
            required: true
        },
        load: {
            type: Function,
            required: true
        }
    },
    data() {
        return {
            showCancelDialog: false,
            EsocialPessoaTipoEnumLabel,
            mCPF
        };
    },
    mounted() {
        this.$service = new EventosService();
    },
    methods: {
        confirmCancelRecords() {
            this.showCancelDialog = true;
        },
        async cancelRecord(id) {
            await this.generateS3000([id]);
        },
        async cancelRecords() {
            const ids = this.selectedRecords.map(element => element.id);

            await this.generateS3000(ids);
        },
        async generateS3000(ids) {
            try {
                await this.$service.sendS3000(ids);
                await this.load();

                showSuccess(this.$toast);
                this.showCancelDialog = false;
            } catch (error) {
                console.error(error);
                showError(this.$toast, error);
            }
        }
    }
};
</script>
