<template>
    <Dialog v-model:visible="showDeleteDialog" :modal="true" :style="{ width: '450px' }"
            header="Confirmar">
        <div class="flex align-items-center justify-content-center">
            <i class="pi pi-exclamation-triangle mr-3" style="font-size: 2rem"/>
            <span v-if="record">Tem certeza que deseja excluir <b>{{ record.name }}</b>?</span>
        </div>
        <template #footer>
            <Button :loading="isLoading" class="p-button-text" icon="pi pi-times" label="Cancelar"
                    @click="showDeleteDialog = false"/>
            <Button :loading="isLoading" class="p-button-outlined p-button-danger" icon="pi pi-trash" label="Excluir"
                    @click="remove"/>
        </template>
    </Dialog>
</template>

<script>
import { showError, showSuccess } from '@/utils/Toast';
import EventosService from './services/EventosService';

export default {
    name: 'DialogShowDelete',
    props: {
        load: {
            type: Function,
            required: true
        }
    },
    data() {
        return {
            showDeleteDialog: false,
            isLoading: false,
            record: undefined
        };
    },
    mounted() {
        this.$service = new EventosService();
    },
    methods: {
        confirm(record) {
            this.record = record;
            this.showDeleteDialog = true;
        },
        async remove() {
            if (this.record) {
                this.isLoading = true;
                try {
                    await this.$service.remove(this.record.id);
                    this.record = {};
                    await this.load();
                    this.showDeleteDialog = false;

                    showSuccess(this.$toast, 'Evento excluído');
                } catch (error) {
                    showError(this.$toast, error, 'Erro ao excluír evento');
                } finally {
                    this.isLoading = false;
                }
            }
        }
    }
};
</script>

<style scoped>

</style>
