<template>
    <Dialog v-model:visible="showDialogHistorico" :breakpoints="{'960px': '75vw', '640px': '90vw'}"
            :header="`Evento: ${currentModalEventoId}`" :modal="true" :style="{width: '80vw'}">
        <DataTable :value="currentModalHistorico" responsiveLayout="scroll">
            <Column :style="{width: '10%'}" field="eventoTipo" header="Tipo"></Column>
            <Column field="customer.name" header="Cliente"></Column>
            <Column field="trabalhador.customerHierarchy.customerBranch.name" header="Unidade"></Column>
            <Column field="trabalhador.name" header="Funcionário"></Column>
            <Column field="trabalhadorMatricula" header="Matrícula"></Column>
            <Column field="data" header="Data evento">
                <template #body="{data}">
                    {{ formatDate(data.data) }}
                </template>
            </Column>
            <Column field="createdAt" header="Data geração">
                <template #body="{data}">
                    {{ formatDate(data.createdAt) }}
                </template>
            </Column>
            <Column>
                <template #body="{data}">
                    <div
                        v-tooltip.left="{value: EsocialEventoSituacaoEnumLabel.get(data.situacao) || '', class: 'custom-width'}">
                        <i :style="{color: EsocialEventoSituacaoEnumColor.get(data.situacao)}"
                           class="pi pi-circle-fill mr-2"></i>
                        {{
                            !data.eventoOriginalId ? 'Original' : (data.eventoRetificadoId ? 'Retificador' : 'Cancelamento')
                        }}
                    </div>
                </template>
            </Column>
            <Column bodyClass="text-right" class="p-2">
                <template #body="{data}">
                    <div class="inline-flex">
                        <Button class="p-button-text p-button-secondary inline-flex mr-2" icon="pi pi-eye"
                                title="Visualizar XML" @click="showXml(data.id)"/>
                    </div>
                </template>
            </Column>
        </DataTable>
    </Dialog>
</template>

<script>
import { showError } from '@/utils/Toast';
import {
    EsocialEventoSituacaoEnumColor,
    EsocialEventoSituacaoEnumLabel
} from '@/pages/esocial/eventos/enums/EsocialEventoSituacaoEnum';
import EventosService from './services/EventosService';

export default {
    name: 'DialogShowHistory',
    props: {
        loading: {
            type: Boolean,
            required: true
        },
        formatDate: {
            type: Function,
            required: true
        },
        showXml: {
            type: Function,
            required: true
        }
    },
    data() {
        return {
            showDialogHistorico: false,
            currentModalEventoId: undefined,
            currentModalHistorico: undefined,
            EsocialEventoSituacaoEnumLabel,
            EsocialEventoSituacaoEnumColor
        };
    },
    mounted() {
        this.$service = new EventosService();
    },
    computed: {
        compLoading: {
            get() {
                return this.loading;
            },
            set(value) {
                this.$emit('update:loading', value);
            }
        }
    },
    methods: {
        async show(data) {
            this.compLoading = true;
            const id = data.eventoOriginalId ?? data.id;

            this.showDialogHistorico = true;
            this.currentModalEventoId = data.eventoId;

            try {
                const { data } = await this.$service.getHistorico(id);
                this.currentModalHistorico = data;
            } catch (error) {
                showError(this.$toast, error, 'Não foi possível obter os eventos');
            } finally {
                this.compLoading = false;
            }
        }
    }
};
</script>
