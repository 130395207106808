<template>
    <DialogShowError ref="DialogShowError"/>
    <DialogShowXml ref="DialogShowXml" v-model:loading="loading"/>
    <DialogShowHistory ref="DialogShowHistory" :formatDate="formatDate" :loading="loading" :showXml="showXml"/>
    <DialogShowDelete ref="DialogShowDelete" :load="load"/>
    <DialogShowCancel ref="DialogShowCancel" :load="load" :selectedRecords="selectedRecords"/>

    <EsocialHeader :isCancelable="isCancelable" :isSendable="isSendable"
                   :load="load" :selectedRecords="selectedRecords" :showCancel="showCancel" title="Eventos"/>

    <DataTable v-model:selection="selectedRecords"
               :loading="loading"
               :row-hover="true"
               :rows="10"
               :selectAll="selectAll"
               :value="records"
               dataKey="id"
               responsiveLayout="scroll"
               @selectAllChange="onSelectAllChange"
               @sort="onSort">
        <template #empty>Nenhum evento encontrado.</template>
        <template #loading>Buscando eventos...</template>

        <Column :exportable="false" selectionMode="multiple" style="width: 3rem"></Column>
        <Column :sortable="true" :style="{'width':'5%'}" field="eventoTipo" header="Tipo"></Column>
        <Column :sortable="true" :style="{'width':'11%'}" field="customer.name" header="Cliente"></Column>
        <Column :sortable="true" :style="{'width':'10%'}" field="trabalhador.customerHierarchy.customerBranch.name"
                header="Unidade"></Column>
        <Column :sortable="true" :style="{'width':'20%'}" field="trabalhador.name" header="Funcionário"></Column>
        <Column :sortable="true" :style="{'width':'5%'}" field="trabalhadorMatricula" header="Matrícula"></Column>
        <Column :sortable="true" :style="{'width':'12%'}" field="data" header="Data evento">
            <template #body="slotProps">
                {{ formatDate(slotProps.data.data) }}
            </template>
        </Column>
        <Column :sortable="true" :style="{'width':'12%'}" field="createdAt" header="Data geração">
            <template #body="slotProps">
                {{ formatDate(slotProps.data.createdAt) }}
            </template>
        </Column>
        <Column :sortable="true" :style="{'width':'25%'}" field="situacao" header="Situação">
            <template #body="slotProps">
                <div v-tooltip.left="{value:slotProps.data.esocialDescricao || '', class: 'custom-width'}">
                    <i :style="{color: EsocialEventoSituacaoEnumColor.get(slotProps.data.situacao)}"
                       class="pi pi-circle-fill mr-2"></i>
                    {{ EsocialEventoSituacaoEnumLabel.get(slotProps.data.situacao) }}
                </div>
            </template>
        </Column>
        <Column bodyClass="text-right" class="p-2">
            <template #body="slotProps">
                <div class="inline-flex">
                    <Button v-if="slotProps.data.eventoRetificadoId || slotProps.data.eventoCanceladoId"
                            v-tooltip.left="'Histórico do evento'"
                            class="p-button-text p-button-secondary inline-flex mr-2" icon="pi pi-info-circle"
                            @click="showHistory(slotProps.data)"/>
                    <Button
                        :disabled="slotProps.data.esocialErros === null || slotProps.data.esocialErros === ''"
                        class="p-button-text p-button-secondary inline-flex mr-2" icon="pi pi-eye"
                        @click="this.$refs.DialogShowError.show(slotProps.data)"/>
                    <Button class="p-button-text p-button-secondary" icon="pi pi-ellipsis-v"
                            @click="toggleMenu($event, slotProps.data)"/>
                </div>
            </template>
        </Column>
    </DataTable>
    <Menu ref="menu" :model="actionItems" :popup="true"/>
    <Paginator
        v-show="!loading"
        :rows="perPage"
        :rowsPerPageOptions="[10, 50, 100]"
        :totalRecords="total"
        currentPageReportTemplate="Mostrando {currentPage} de {totalPages} registros"
        paginatorTemplate=" PrevPageLink PageLinks NextPageLink RowsPerPageDropdown"
        @page="onPage($event)"/>
</template>

<script>
import {
    EsocialEventoSituacaoEnum,
    EsocialEventoSituacaoEnumColor,
    EsocialEventoSituacaoEnumLabel
} from './enums/EsocialEventoSituacaoEnum';
import { EsocialEventoTipoEnum } from './enums/EsocialEventoTipoEnum';
import EsocialHeader from '../_shared/EsocialHeader.vue';
import moment from 'moment';
import DialogShowError from '@/pages/esocial/eventos/DialogShowError.vue';
import DialogShowXml from '@/pages/esocial/eventos/DialogShowXml.vue';
import DialogShowHistory from '@/pages/esocial/eventos/DialogShowHistory.vue';
import DialogShowDelete from '@/pages/esocial/eventos/DialogShowDelete.vue';
import DialogShowCancel from '@/pages/esocial/eventos/DialogShowCancel.vue';
import EventosService from './services/EventosService';
import EventBus, { EVENTS } from '../../../event-bus';

export default {
    components: {
        DialogShowCancel,
        DialogShowDelete,
        DialogShowHistory,
        DialogShowXml,
        DialogShowError,
        EsocialHeader
    },
    data() {
        return {
            path: '/esocial/eventos',
            records: [],
            selectedRecords: [],
            page: 1,
            total: 0,
            loading: false,
            totalPage: 0,
            perPage: 10,
            sort: null,
            selectAll: false,
            filter: undefined,
            isCancelable: false,
            isSendable: false,
            actionItems: [
                {
                    label: 'Visualizar XML',
                    icon: 'pi pi-eye',
                    disabled: () => !this.$checkPermission('esocial_eventos:view'),
                    command: () => this.showXml(this.actionRecord.id)
                },
                {
                    label: 'Alterar',
                    icon: 'pi pi-pencil',
                    visible: () => !this.actionRecord.esocialRecibo,
                    disabled: () => !this.$checkPermission('esocial_eventos:editar'),
                    command: () => this.editRecord(this.actionRecord.id)
                },
                {
                    label: 'Retificar',
                    icon: 'pi pi-pencil',
                    visible: () => this.actionRecord.esocialRecibo
                        && this.actionRecord.situacao === EsocialEventoSituacaoEnum.ESOCIAL_APROVADO
                        && this.actionRecord.eventoTipo !== EsocialEventoTipoEnum.S3000,
                    disabled: () => !this.$checkPermission('esocial_eventos:retificar'),
                    command: () => this.editRecord(this.actionRecord.id)
                },
                {
                    label: 'Excluir',
                    icon: 'pi pi-trash',
                    visible: () => this.actionRecord.situacao < EsocialEventoSituacaoEnum.ESOCIAL_APROVADO,
                    disabled: () => !this.$checkPermission('esocial_eventos:excluir'),
                    command: () => this.$refs.DialogShowDelete.confirm(this.actionRecord)
                },
                {
                    label: 'Gerar S-3000',
                    icon: 'pi pi-trash',
                    visible: () => this.actionRecord.situacao === EsocialEventoSituacaoEnum.ESOCIAL_APROVADO
                        && this.actionRecord.eventoTipo !== EsocialEventoTipoEnum.S3000,
                    disabled: () => !this.$checkPermission('esocial_eventos:excluir'),
                    command: () => this.cancel(this.actionRecord.id)
                }
            ],
            EsocialEventoSituacaoEnum,
            EsocialEventoSituacaoEnumLabel,
            EsocialEventoSituacaoEnumColor,
        };
    },
    mounted() {
        this.$service = new EventosService(this.path);
        this.load();

        EventBus.on(EVENTS.FOOTER_UPDATED, this.load);
    },
    beforeUnmount() {
        EventBus.off(EVENTS.FOOTER_UPDATED, this.load);
    },
    watch: {
        selectedRecords() {
            this.isSendable = !!(this.selectedRecords.length &&
                this.selectedRecords.every((current) => (
                    current.situacao === EsocialEventoSituacaoEnum.AGUARDANDO_ENVIO ||
                    current.situacao === EsocialEventoSituacaoEnum.ESOCIAL_ERRO_LOTE
                )));

            this.isCancelable = !!(this.selectedRecords.length &&
                this.selectedRecords.every((current) => (
                    current.situacao === EsocialEventoSituacaoEnum.ESOCIAL_APROVADO &&
                    current.eventoTipo !== EsocialEventoTipoEnum.S3000
                )));
        }
    },
    methods: {
        showHistory(data) {
            this.$refs.DialogShowHistory.show(data);
        },
        showXml(id) {
            this.$refs.DialogShowXml.show(id);
        },
        showCancel() {
            this.$refs.DialogShowCancel.confirmCancelRecords();
        },
        cancel(id) {
            this.$refs.DialogShowCancel.cancelRecord(id);
        },
        formatDate(dateString) {
            const formatDates = moment.utc(dateString).format('DD/MM/YYYY');
            return `${formatDates}`;
        },
        async load(filter = null) {
            this.loading = true;
            this.filter = filter;

            const { data } = await this.$service.findAll({
                limit: this.currentPerPage,
                page: this.page,
                filter: this.filter,
                sort: this.sort
            });

            this.records = data.items;
            this.total = data.meta?.totalItems;
            this.totalPage = data.meta?.totalPages;
            this.loading = false;
        },
        async onSelectAllChange(event) {
            const selectAll = event.checked;
            this.loading = true;

            if (selectAll) {
                this.selectAll = true;
                const { data } = await this.$service.selectAll(this.filter);
                this.selectedRecords = data;
            } else {
                this.selectAll = false;
                this.selectedRecords = [];
            }

            this.loading = false;
        },
        toggleMenu(event, data) {
            this.actionRecord = data;
            this.$refs.menu.toggle(event);
        },
        editRecord(id) {
            this.$router.push('/esocial/' + id);
        },
        async onPage(event) {
            this.page = event.page + 1;
            this.currentPerPage = event.rows;

            await this.load();
        },
        async onSort(event) {
            this.currentSortField = event.sortField;
            this.currentSortOrder = event.sortOrder;

            const field = event.sortField
                ?.split(/(?=[A-Z])/)
                .join('_')
                .toLowerCase()
                ? `${event.sortField
                    ?.split(/(?=[A-Z])/)
                    .join('_')
                    .toLowerCase()}`
                : '';
            const order = event.sortOrder === 1 ? `ASC` : 'DESC';
            if (field) {
                this.sort = {
                    [field]: order
                };
            } else {
                this.sort = null;
            }

            await this.load();
        }
    }
};
</script>

<style lang="scss">
</style>
