import BaseService from '../../../../services/BaseService';
import { getClientBase } from '../../../../services/http';

export default class EventosService extends BaseService {
    constructor() {
        super('esocial/eventos');
        this.path = 'esocial/eventos';
    }

    async selectAll(filter) {
        return await getClientBase().get(`${this.path}/select-all`, {
            params: {
                filter
            }
        });
    }

    async getHistorico(id) {
        return await getClientBase().get(`${this.path}/${id}/historico`);
    }

    async getXml(id) {
        return await getClientBase().get(`${this.path}/${id}/xml`);
    }

    async sendS3000(ids) {
        return await getClientBase().post('/esocial/lotes/S3000', ids);
    }
}
